@font-face {
  font-family: Raleway-Regular;
  src: url("./fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: Raleway-Bold;
  src: url("./fonts/Raleway-Bold.ttf");
}
@font-face {
  font-family: Raleway-Italic;
  src: url("./fonts/Raleway-Italic.ttf");
}
@font-face {
  font-family: Raleway-BoldItalic;
  src: url("./fonts/Raleway-BoldItalic.ttf");
}
@font-face {
  font-family: FiraCode-Regular;
  src: url("./fonts/FiraCode-Regular.ttf");
}
@font-face {
  font-family: FiraCode-Bold;
  src: url("./fonts/FiraCode-Bold.ttf");
}

body {
  margin: 0;
  font-family: "Raleway-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.table-filter {
  float: right;
  padding: 0.5em 0;
}

.modal-content {
  padding: 1em;
}

.form-error {
  color: red;
}
